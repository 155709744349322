// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-blogpost-js": () => import("/home/runner/work/holzliebe/holzliebe/src/templates/blogpost.js" /* webpackChunkName: "component---src-templates-blogpost-js" */),
  "component---src-pages-404-js": () => import("/home/runner/work/holzliebe/holzliebe/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("/home/runner/work/holzliebe/holzliebe/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-js": () => import("/home/runner/work/holzliebe/holzliebe/src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-datenschutz-js": () => import("/home/runner/work/holzliebe/holzliebe/src/pages/datenschutz.js" /* webpackChunkName: "component---src-pages-datenschutz-js" */),
  "component---src-pages-holzarchitektur-js": () => import("/home/runner/work/holzliebe/holzliebe/src/pages/holzarchitektur.js" /* webpackChunkName: "component---src-pages-holzarchitektur-js" */),
  "component---src-pages-impressionen-js": () => import("/home/runner/work/holzliebe/holzliebe/src/pages/impressionen.js" /* webpackChunkName: "component---src-pages-impressionen-js" */),
  "component---src-pages-impressum-js": () => import("/home/runner/work/holzliebe/holzliebe/src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("/home/runner/work/holzliebe/holzliebe/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("/home/runner/work/holzliebe/holzliebe/src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-raumplanung-js": () => import("/home/runner/work/holzliebe/holzliebe/src/pages/raumplanung.js" /* webpackChunkName: "component---src-pages-raumplanung-js" */),
  "component---src-pages-tischlerei-js": () => import("/home/runner/work/holzliebe/holzliebe/src/pages/tischlerei.js" /* webpackChunkName: "component---src-pages-tischlerei-js" */)
}

