module.exports = [{
      plugin: require('/home/runner/work/holzliebe/holzliebe/node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography.js"},
    },{
      plugin: require('/home/runner/work/holzliebe/holzliebe/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"G-96JYGXTDLN","head":false,"exclude":["/preview/**","/do-not-track/me/too/"],"pageTransitionDelay":0,"defer":false},
    },{
      plugin: require('/home/runner/work/holzliebe/holzliebe/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
